import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  UploadFile(file: File, overwrite: boolean = false) {
    const formData: FormData = new FormData();
    formData.append('File', file);

    return this.http.put<any>(`${this.BASE_URL}api/media/upload?overwrite=${overwrite}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  Exists(filename: string) {
    return this.http.get<boolean>(`${this.BASE_URL}api/media/exists?filename=${filename}`);
  }
}
