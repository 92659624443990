import * as fromUploads from './uploads.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Media';

export interface MediaState {
  uploads: fromUploads.UploadsState;
}

export const reducers: ActionReducerMap<MediaState> = {
  uploads: fromUploads.UploadsReducer,
};

export const getMediaState = createFeatureSelector<MediaState>(featureKey);

export const metaReducers: MetaReducer<MediaState>[] = !environment.production ? [] : [];
