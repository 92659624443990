import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/uploads.action';
import { Media } from '../../models';

export interface UploadsState {
  uploads: {
    [filename: string]: {
      progress: number;
      contentLength: number;
      transfered: number;
      loaded: boolean;
      loading: boolean;
      media?: Media;
      error?: any;
    };
  };
}

export const initialState: UploadsState = {
  uploads: {},
};

export const UploadsReducer = createReducer(
  initialState,
  on(actions.UploadFile, (state, { file }) => {
    return {
      ...state,
      uploads: {
        ...state.uploads,
        [file.name]: {
          progress: 0,
          contentLength: file.size,
          transfered: 0,
          loaded: false,
          loading: true,
        },
      },
    };
  }),
  on(actions.UploadFileSuccess, (state, { file, media }) => {
    return {
      ...state,
      uploads: {
        ...state.uploads,
        [file.name]: {
          ...state.uploads[file.name],
          media,
          progress: 100,
          loaded: true,
          loading: false,
        },
      },
    };
  }),
  on(actions.UploadFileProgress, (state, { event, file }) => {
    const progress = Math.round((event.loaded / (event.total || 0)) * 100);
    return {
      ...state,
      uploads: {
        ...state.uploads,
        [file?.name]: {
          ...state.uploads[file?.name],
          progress,
          transfered: event.loaded,
          contentLength: event.total || 0,
        },
      },
    };
  }),
  on(actions.UploadFileFail, (state, { error, file }) => {
    return {
      ...state,
      uploads: {
        ...state.uploads,
        [file?.name]: {
          ...state.uploads[file?.name],
          loaded: false,
          loading: false,
          error,
        },
      },
    };
  })
);

export const getUploads = (state: UploadsState) => state.uploads;
